var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select_style" },
    [
      _vm.requiredIcon
        ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        staticClass: "select_input input_style",
        style: _vm.itemInputStyle,
        attrs: { readonly: "", placeholder: _vm.placeholder },
        domProps: { value: _vm.getContentText(_vm.value) },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleList.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "arrow faisco-icons-S000112",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleList.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("keep-alive", [
        _vm.list.length > 0 && _vm.showList
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show-pc-list",
                    rawName: "v-show-pc-list",
                    value: this,
                    expression: "this",
                  },
                  { name: "dom-portal", rawName: "v-dom-portal" },
                ],
                staticClass: "select_list_wrap",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "search_box_wrapper",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm.showSearch
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchKey,
                              expression: "searchKey",
                            },
                          ],
                          staticClass: "search_box",
                          domProps: { value: _vm.searchKey },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.searchKey = $event.target.value
                              },
                              _vm.changeSearchKey,
                            ],
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("svg", { staticClass: "search_box_icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon_search" } }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.selectItems.length > 0
                  ? _c(
                      "ul",
                      { staticClass: "select_list" },
                      _vm._l(_vm.selectItems, function (item, index) {
                        return _c(
                          "li",
                          {
                            staticClass: "select_item",
                            class: { select_active: _vm.value === item },
                            on: {
                              click: function ($event) {
                                return _vm.selectItem(item, index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.getContentText(item)) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", { staticClass: "search_not_found" }, [
                      _vm._v(_vm._s(_vm.LS.js_not_matched || "无匹配内容")),
                    ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "PopupLayerComponent",
        {
          attrs: { "is-link-to-form": _vm.isLinkToForm },
          model: {
            value: _vm.mobiShowList,
            callback: function ($$v) {
              _vm.mobiShowList = $$v
            },
            expression: "mobiShowList",
          },
        },
        [
          _c("div", { staticClass: "p_mobi_picker" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                { staticClass: "btn cancel", on: { click: _vm.mobiCancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn confirm", on: { click: _vm.mobiConfirm } },
                [_vm._v("确定")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                ref: "list",
                staticClass: "list",
                on: {
                  touchstart: _vm.onTouchStart,
                  mousedown: _vm.onTouchStart,
                },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c("li", { key: index, staticClass: "item" }, [
                  _vm._v(_vm._s(_vm.getContentText(item))),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }